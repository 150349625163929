import React from "react"
import FirstLevelBanner from "../components/our-work/first-level/Banner"
import Banner from "../components/our-work/second-level/Banner"
import OurWorkComponent from "../components/OurWorkComponent"
import ContributionTrain from "../components/our-work/second-level/ContributionTrain"
import DonationBox from "../components/our-work/second-level/DonationBox"
import Content from "../components/our-work/second-level/Content"
import Media from "../components/our-work/second-level/Media"

const SecondLevelLayout = ({ data }) => {
  return (
    <>
        {data && data.strapiContents.donationStatus ? <Banner sources={data.strapiContents.HeroImage} /> : <FirstLevelBanner sources={data.strapiContents.HeroImage} /> }
        {data && data.strapiContents.donationStatus &&
          <ContributionTrain
              trainText={data.strapiContents.trainText}
              target={data.strapiContents.target} 
              raised={data.strapiContents.raised}
              raisedOnline={data.strapiContents.raisedOnline}
              raisedOffline={data.strapiContents.raisedOffline}
              targetType={data.strapiContents.targetType} 
              cost={data.strapiContents.cost} 
          />
        }
        <section className="container">
            <h1 className="page-title">{data.strapiContents.header}</h1>
            <div className="work-container">
            {data && data.strapiContents.donationStatus &&
              <DonationBox
                  target={data.strapiContents.target}
                  raised={data.strapiContents.raised}
                  raisedOnline={data.strapiContents.raisedOnline}
                  raisedOffline={data.strapiContents.raisedOffline}
                  currentAppeal={data.strapiContents.currentAppeal}
                  formID={data.strapiContents.formID}
                  zakatEligible={data.strapiContents.zakatEligible}
              />
            }
            <Content content={data.strapiContents.content} projectUpdates={data.strapiContents.ProjectUpdates} donationStatus={data.strapiContents.donationStatus} />
            </div>
        </section>
        <Media imageSource={data.strapiContents && data.strapiContents.pictures} videoSource={data.strapiContents && data.strapiContents.videos} />
        <OurWorkComponent />
    </>
  )
}

export default SecondLevelLayout
