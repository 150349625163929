import React from "react"
import MainLayout from "../layouts/MainLayout"
import SecondLevelLayout from "../layouts/SecondLevelLayout"
import { graphql } from "gatsby"
import SEO from "../components/SEO"

export default function SecondLevelPage({ data }) {
    return (
        <MainLayout>
            <SEO title={`${data.strapiContents.seo.title}`} description={`${data.strapiContents.seo.description}`} />
            <SecondLevelLayout data={data} />
        </MainLayout>
    )
}

export const pageQuery = graphql`  
    query($slug: String!) {
        strapiContents(slug: {eq: $slug}) {
            content
            cost
            header
            raised
            target
            trainText
            currentAppeal
            raisedOnline
            raisedOffline
            targetType
            formID
            zakatEligible
            HeroImage {
                desktopImage {
                    localFile{
                        publicURL
                    }
                }
                mobileImage {
                    localFile{
                        publicURL
                    }
                }
            }
            pictures {
                id
                width
                height
                alternativeText
                url
            }
            seo {
                title
                description
                keywords
            }
            videos {
                code
                description
                coverImage {
                    localFile{
                        publicURL
                    }
                }
            }
            ProjectUpdates {
                Content
                Date(formatString: "DD/MM/YYYY")
                Header
                ProjectName
                Region
            }
            donationStatus
        }
  }
`
