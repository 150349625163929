import React, { useEffect } from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import FeedingLogo from "../assets/images/our-work/feeding-logo.svg"
import SafeWaterLogo from "../assets/images/our-work/safe-water-logo.svg"
import PeaceTrainingLogo from "../assets/images/our-work/peace-training-logo.svg"
import Playgrounds from "../assets/images/our-work/playgrounds-logo.svg"

const query = graphql`
  {
    strapiMenus {
      Dropdown {
        data {
          header
          buttons {
            header
            id
            logoLink
            children {
              link
              text
            }
          }
        }
      }
    }
  }
`

const OurWorkComponent = () => {
  const { strapiMenus } = useStaticQuery(query)
  useEffect(() => {
    const type = window.location.pathname.split('/')[2]
    if (document.getElementById(type) !== null) {
      collapseButton(document.getElementById(type))
    }
  })

  const collapseButton = e => {
    const $contentList = e.currentTarget ? e.currentTarget.nextElementSibling : e.nextElementSibling
    $contentList.classList.toggle("content-links-active")
    if ($contentList.style.maxHeight) {
      $contentList.style.maxHeight = null
    } else {
      $contentList.style.maxHeight = `${$contentList.scrollHeight}px`
    }
  }

  return (
    <section className="our-partners">
      <h2>our work</h2>
      <div className="menu">
        {strapiMenus.Dropdown.data.map((column,index) => (
          <div className={`menu-column ${index === 0 ? "first" : "second"}`}>
            <h3 className="our-work-h3">{column.header}</h3>
            {column.buttons.map((item,index) => (
              <div className="menu-column">
                <button type="button" id={item.id} onClick={collapseButton} className={index != 0 && 'second-buttons'}>
                  <h4>{item.header}</h4>
                  <img src={`${process.env.API_URL}${item.logoLink}`} width={48} alt={`${item.header} Logo`} />
                </button>

                <div className="content-links">
                  <ul className="content-links-list">
                    {item.children.map(child => (
                      <li>
                        <Link to={child.link}>
                          {child.text}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </section>
  )
}

export default OurWorkComponent
